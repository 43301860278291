.root {
  grid-column: 1 / -1;
}

.center {
  text-align: center;
}

.content {
  border: 1px solid var(--stroke-1);
  border-radius: var(--cardRadius-1);
  padding: 2.5rem;
}

.privacy {
  text-align: center;
}
