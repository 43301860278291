.close {
  align-self: flex-end;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
