.intrest {
  border: 1px solid var(--stroke-1);
  border-radius: var(--cardRadius-2);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.counter {
  background-color: var(--text-1);
  color: var(--background);
  border-radius: 6.25rem;
  padding: 0.125rem 0.375rem;
}

.photoBadge {
  position: absolute;
  top: var(--spacingS);
  left: var(--spacingS);
}

.marginTop {
  margin-top: var(--spacingXXL);
}

.propertyCard {
  cursor: pointer;
}

.propertyCard:hover {
  background: var(--onBackground);
}
